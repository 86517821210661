const firstJobsComponent = document.querySelector('.first-jobs-section');
if (firstJobsComponent) {
  const advancedToggle = firstJobsComponent.querySelector(
    '.first-jobs-section__form-toggle'
  );
  const advancedContent = firstJobsComponent.querySelector(
    '.first-jobs-section__form-hidden'
  );

  if (advancedToggle) {
    advancedToggle.addEventListener('click', () => {
      if (!advancedToggle.classList.contains('active')) {
        advancedToggle.classList.add('active');
        advancedContent.classList.add('active');

        setTimeout(function () {
          advancedContent.classList.add('is-visible');
        }, 150);
      } else {
        advancedToggle.classList.remove('active');
        advancedContent.classList.remove('active');

        setTimeout(function () {
          advancedContent.classList.remove('is-visible');
        }, 150);
      }
    });
  }
}
